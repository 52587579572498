import React,{useEffect} from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function DappBrowser() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
    <div className='header_bar_sticky_align'>
    <Header/>
    </div>

    <Container fluid className='banner_bg'>
        <Container>

        <Row className='banner_top_align'>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className='banner_align_dtls d-flex justify-content-center h-100 align-items-center'>
                <div className='wrap_dtls'>
            <h1 className='banner_title'>RoRo Wallet<br/> Dapp Browser</h1>
            <p className=' mt-3'>Wain a minute.. What is a DApp Browser you ask?</p>
            <p className=' mt-3'>A dapp (decentralized application) browser is a software program that allows users to access decentralized applications, or dapps, on a blockchain network. These applications are built on top of blockchain technology, which allows for secure and transparent interactions without the need for a central authority.</p>

            <p className='mt-3'>Think of a dapp browser as a portal to access a new world of decentralized applications, where users can interact with others, make transactions, and exchange value in a secure and transparent way.</p>
            <div className='banner_btn_align mt-5 pb-5'>
             
            <button className='download_now_btn_align'>Coming soon</button>
            </div>
            </div>
            </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <img className='img-fluid' src={require('../assets/images/asset page/black_hand.png')}></img>
            </Col>
        </Row>

        </Container>
    </Container>

    <Container className='mt-5'>
    <h3 className='text-center doodle_title'>Explore a plethora of DApps with RoRo</h3>
    <p className='text-center'>Since each DApp is different, we collaborate with developers to provide our users with the greatest experience possible. The Marketplace includes decentralized applications (DApps) that have been examined and enhanced for RoRo Wallet. Our objective is to establish a community of decentralized applications that can be accessed by anybody with a mobile device, therefore the list is continuously growing.
</p>

    <Row className=' justify-content-center '>
        <Col className='mb-3 dapp_img_ctntdiv_align' xxl={4} xl={4} lg={4} md={4} sm={6} xs={12}>
            <img className='img-fluid dapp_img_ctnt_align' src={require('../assets/images/RORO Website inner pages images/DApp page/handmob.png')}/>
        </Col>
        <Col className='mb-3 dapp_img_ctntdiv_align' xxl={4} xl={4} lg={4} md={4} sm={6} xs={12}>
            <img className='img-fluid dapp_img_ctnt_align' src={require('../assets/images/RORO Website inner pages images/DApp page/lefthandmob.png')}/>
        </Col>
        <Col className='mb-3 dapp_img_ctntdiv_align' xxl={4} xl={4} lg={4} md={4} sm={6} xs={12}>
            <img className='img-fluid dapp_img_ctnt_align' src={require('../assets/images/RORO Website inner pages images/DApp page/mobile.png')}/>
        </Col>
    </Row>
    </Container>

    <Container className='mt-5'>
        <h3 className='text-center doodle_title'>Dive into crypto with 3 easy steps</h3>
        <p className='text-center doodle_title'>It only takes few minutes</p>
    <Row className='mt-5 justify-content-around' >
            <Col  xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/download_roro.png')}/>
                <p className='explore_content_hint mt-3'>Download RoRo Wallet</p>
                </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/Hand_of_user.png')}/>
                <p className='explore_content_hint text-center mt-3'>Create a new Wallet</p>
                </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/buy_some_crypto.png')}/>
                <p className='explore_content_hint text-center mt-3'>Get some Crypto</p>
                </div>
                
            </Col>
            <div className='w-100 mt-5 d-flex justify-content-center'>
        <button className='explore_content_download_btn'>Let's Go</button>
        </div>
        </Row>
    </Container>
    <Container fluid className='features_opt_dtls mt-5'>
        <div className='w-100 pt-4 pb-4'>
    <h3 className='text-center doodle_title'>Get RoRo Wallet now</h3>
        <p className='text-center explore_content_hint'>The most Trusted & Secure wallet</p>
        <div className='container '>
            <div className='row'>
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_firstdowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align' src={require('../assets/images/apple_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'> Coming soon</p>
                    <h6 className='download_hint_align'>APP STORE</h6>
                </div>
            </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_seconddowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/playstore_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>Google Play</h6>
                </div>
            </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_thirddowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/android_doll.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>Android</h6>
                </div>
            </div>
                    
                </div>


            </div>
        </div>
        </div>
        

    </Container>


    <Footer/>
    </>
  )
}

export default DappBrowser