import React,{useEffect} from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function SecurelyStore() {

        useEffect(() => {
          window.scrollTo(0, 0);
        }, []);


  return (
    <>
    <div className='header_bar_sticky_align'>
    <Header/>
    </div>
    <Container fluid className='banner_bg'>
        <Container>

        <Row className='banner_top_align'>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className='banner_align_dtls d-flex justify-content-center h-100 align-items-center'>
                <div className='wrap_dtls'>
            <h1 className='banner_title'>Securely Store more <br/> than 1.5M+ assets with <br/> Confidence</h1>
            <p className='banner_hint mt-3'>Store all your coins, tokens, and NFTs at a single destination! </p>
            <div className='banner_btn_align mt-5 pb-5'>
             
            <button className='download_now_btn_align'>Coming soon</button>
            </div>
            </div>
            </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <img className='img-fluid' src={require('../assets/images/asset page/black_hand.png')}></img>
            </Col>
        </Row>

        </Container>
    </Container>

    <Container className='mt-5'>
        <h3 className='text-center doodle_title'>Your All-in-One Crypto Companion</h3>
        <Row className='mt-5'>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className='companion_div_align'>
                    <img className='tick_img_align' src={require('../assets/images/asset page/tick-mark.png')}/>
                    <p className='m-0 p-0'>Buy,swap & hold a variety of crypto and <br/> NFT assets</p>
                </div>
                <div className='companion_div_align'>
                    <img className='tick_img_align' src={require('../assets/images/asset page/tick-mark.png')}/>
                    <p className='m-0 p-0'>Earn interest on your digital assets in just a few clicks</p>
                </div>
                <div className='companion_div_align'>
                    <img className='tick_img_align' src={require('../assets/images/asset page/tick-mark.png')}/>
                    <p className='m-0 p-0'>Swap or trade your favourite assets in seconds</p>
                </div>
            </Col>

            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className='companion_div_align'>
                    <img className='tick_img_align' src={require('../assets/images/asset page/tick-mark.png')}/>
                    <p className='m-0 p-0'>Chat securely with your peers</p>
                </div>
                <div className='companion_div_align'>
                    <img className='tick_img_align' src={require('../assets/images/asset page/tick-mark.png')}/>
                    <p className='m-0 p-0'>Simplify crypto buying with credit/debit card purchases</p>
                </div>
                <div className='companion_div_align'>
                    <img className='tick_img_align' src={require('../assets/images/asset page/tick-mark.png')}/>
                    <p className='m-0 p-0'>Stay safe private and secure with RoRo</p>
                </div>
            </Col>
        </Row>
    </Container>

    <Container className='mt-5'>
    <h3 className='text-center doodle_title'>RoRo lets you store and manage a world of assets</h3>
        <Row className='mt-5'>
            <Col className='p-0' xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/ethereum.png')}/>
                    <p className='m-0 p-0'><b>Ethereum </b> (ETH)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/tron.png')}/>
                    <p className='m-0 p-0'><b>Tron </b> (TRX)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/wanchain.png')}/>
                    <p className='m-0 p-0'><b>Wanchain </b> (WAN)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/cronos.png')}/>
                    <p className='m-0 p-0'><b>Cronos Coin </b> (CRO)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/dai.png')}/>
                    <p className='m-0 p-0'><b>DAI Stablecoin </b> (DAI)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/shiba.png')}/>
                    <p className='m-0 p-0'><b>SHIBA INU </b> (SHIB)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/loopering.png')}/>
                    <p className='m-0 p-0'><b>Loopring </b> (LRC)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/populous.png')}/>
                    <p className='m-0 p-0'><b>Populous </b> (PPT)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/sand.png')}/>
                    <p className='m-0 p-0'><b>SAND </b> (SAND)</p>
                </div>
            </Col>
            <Col className='p-0' xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/polygon.png')}/>
                    <p className='m-0 p-0'><b>Polygon </b> (matic)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/xdc_icon.png')}/>
                    <p className='m-0 p-0'><b>Xinfin Network </b> (XDC)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/tether.png')}/>
                    <p className='m-0 p-0'><b>Tether </b> (USDT)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/Maker.png')}/>
                    <p className='m-0 p-0'><b>Maker </b> (MKR )</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/HEXagon.png')}/>
                    <p className='m-0 p-0'><b>Hex </b> (HEX)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/golem.png')}/>
                    <p className='m-0 p-0'><b>Golem </b> (GNT)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/reputation.png')}/>
                    <p className='m-0 p-0'><b>Reputation </b> (REPv2)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/IOstoken.png')}/>
                    <p className='m-0 p-0'><b>IOSToken </b> (IOST)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/quant.png')}/>
                    <p className='m-0 p-0'><b>Quant </b> (QNT)</p>
                </div>
            </Col>

            <Col className='p-0' xxl={4} xl={4} lg={4} md={4} sm={12} xs={12}>
            <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/binance.png')}/>
                    <p className='m-0 p-0'><b>Binance coin </b> (BNB)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/ripple.png')}/>
                    <p className='m-0 p-0'><b>Ripple </b> (XRP)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/apecoin.png')}/>
                    <p className='m-0 p-0'><b>ApeCoin </b> (APE)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/aave.png')}/>
                    <p className='m-0 p-0'><b>Aave </b> (AAVE )</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/bitfin.png')}/>
                    <p className='m-0 p-0'><b>Bitfinex Leo Token </b> (ETH)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/ZRX.png')}/>
                    <p className='m-0 p-0'><b>ZRX </b> (ZRX)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/status.png')}/>
                    <p className='m-0 p-0'><b>StatusNetwork </b> (SNT)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/kucoin.png')}/>
                    <p className='m-0 p-0'><b>KuCoin Token </b> (KCD)</p>
                </div>
                <div className='world_asset_div_align'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/budblock.png')}/>
                    <p className='m-0 p-0'><b>BudBlocks </b> (BLUNT)</p>
                </div>
            </Col>
        </Row>
        <p className='one_million_whole_align'><Link to="#" className='one_million_text_align'>+1 Million more...</Link></p>
    </Container>

    <Container className='mt-5'>
        <h3 className='text-center doodle_title'>Dive into crypto with 3 easy steps</h3>
        <p className='text-center doodle_title'>It only takes few minutes</p>
    <Row className='mt-5 justify-content-around' >
            <Col  xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/download_roro.png')}/>
                <p className='explore_content_hint mt-3'>Download RoRo Wallet</p>
                </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/Hand_of_user.png')}/>
                <p className='explore_content_hint text-center mt-3'>Create a new Wallet</p>
                </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/buy_some_crypto.png')}/>
                <p className='explore_content_hint text-center mt-3'>Get some Crypto</p>
                </div>
                
            </Col>
            <div className='w-100 mt-5 d-flex justify-content-center'>
        <button className='explore_content_download_btn'>Let's Go</button>
        </div>
        </Row>
    </Container>
    <Container fluid className='features_opt_dtls mt-5'>
        <div className='w-100 pt-4 pb-4'>
    <h3 className='text-center doodle_title'>Get RoRo Wallet now</h3>
        <p className='text-center explore_content_hint'>The most Trusted & Secure wallet</p>
        <div className='container '>
            <div className='row'>
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_firstdowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align' src={require('../assets/images/apple_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>APP STORE</h6>
                </div>
            </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_seconddowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/playstore_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>Google Play</h6>
                </div>
            </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_thirddowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/android_doll.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>Android</h6>
                </div>
            </div>
                    
                </div>


            </div>
        </div>
        </div>
        

    </Container>

    <Footer/>
    </>
  )
}

export default SecurelyStore