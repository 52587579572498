import React from 'react'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Application from '../Screens/Application';
import DappBrowser from '../Screens/DappBrowser';

  import HomePage from '../Screens/HomePage'
import NftHub from '../Screens/NftHub';
import PrivacyPolicy from '../Screens/PrivacyPolicy';
import SecurelyStore from '../Screens/SecurelyStore';
import Staking from '../Screens/Staking';

function RoutesDtls() {
  return (
    
    <>
         <Router basename="/">
          <Switch>
            <Route exact path="/" component={HomePage} /> 
            <Route exact path="/secureStore" component={SecurelyStore} />
            <Route exact path="/dappBrowser" component={DappBrowser} /> 
            <Route exact path="/staking" component={Staking} /> 
            <Route exact path="/nfthub" component={NftHub} />  
            <Route exact path="/application" component={Application} />     
            <Route exact path="/privacyPolicy" component={PrivacyPolicy} />       
          </Switch>
        </Router>
    {/* <Routes>
        <Route exact path='/' element={<HomePage/>}></Route>
        
        </Routes> */}
        
        </>
  )
}

export default RoutesDtls