import React,{useEffect} from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import Header from '../Components/Header'
import Footer from '../Components/Footer'

function Staking() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
    <div className='header_bar_sticky_align'>
    <Header />
    </div>
    <Container className='mt-5'>
        <div>
            <h3 className='text-center doodle_title'>What is Staking ?</h3>
            <p className=' stacking_clearance_txt text-center mt-4'>One thing unites all blockchains: transactions must be verified. For instance, Bitcoin does this through a procedure known as mining, which is known to consume a lot of electricity (Proof-of-Work). Other consensus processes, however, are employed for validation. One such consensus technique is proof-of-stake (PoS), which has a number of distinct forms as well as several hybrid models. We will collectively refer to all of these as staking to keep things clear. Coin staking offers currency owners some influence over network decisions. You become eligible to vote and make money by staking coins. It is quite comparable to how interest is paid when money is kept in a bank account or given to the bank to invest.
</p>

        </div>
    </Container>
    <Container>
        <Row>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className='stacking_brief_align'>
                <h3>How does Staking benefit me as a user?</h3>
                <p className='stacking_brief_clearance_txt mt-3'>One of the main advantages of staking coins is that it eliminates the need for ongoing energy consumption and the purchase of pricey hardware.
</p>

                <p className='stacking_brief_clearance_txt'>In contrast to the proof-of-work system, where coins are rewarded through a mathematical process with a low possibility of paying out, the method offers guaranteed returns and a predictable source of revenue. </p>

                <p className='stacking_brief_clearance_txt'>Another advantage is that, unlike with ASICs and other mining equipment, the value of the coins you stake never decreases. Coins that have been staked are only impacted by changes in market value.
</p>
                </div>
            </Col>

            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <img className='img-fluid' src={require('../assets/images/RORO Website inner pages images/staking page/loceker_coin.png')}></img>
            </Col>
        </Row>

        <Row className='row_reverse_align'>
        <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <img className='img-fluid' src={require('../assets/images/RORO Website inner pages images/staking page/hand_leaf.png')}></img>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className='stacking_brief_align'>
                <h3>How does it work ?</h3>
                <p className='stacking_brief_clearance_txt mt-3'>The typical staking methods often include keeping money in your wallet or placing them in a smart contract (masternodes). Some coins made the voting and staking processes random, making it more difficult for undesirable players to rig the results.</p>

                <p className='stacking_brief_clearance_txt'>The procedure can resemble a lottery, where the quantity of cryptocurrency coins you possess is equivalent to a specific number of lottery tickets. Staking systems can also include delegation, whereby each user gives a reliable third party control over their votes and income.</p>

                <p className='stacking_brief_clearance_txt'>Then, those delegates receive all the benefits for validating the block, and they compensate their devoted followers with dividends in exchange for their votes.</p>
                </div>
            </Col>

           
        </Row>
    </Container>

    <Container className='mt-5'>
        <h3 className='text-center doodle_title'>
        DYOR: DO YOUR OWN RESEARCH. Always!
        </h3>
        <p className='stacking_brief_clearance_txt mt-3'>Choosing the best coin to stake involves both math and intuition. If you decide to start staking, you should definitely experiment with small amounts and specific staking rewards. Always choose a project that speaks to you and that you believe will last for a very long time. After all, by staking, you're assisting in the success of that initiative.
   </p>
    </Container>

    <Container className='mt-5'>
        <h3 className='text-center doodle_title'>Dive into crypto with 3 easy steps</h3>
        <p className='text-center doodle_title'>It only takes few minutes</p>
    <Row className='mt-5 justify-content-around' >
            <Col  xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/download_roro.png')}/>
                <p className='explore_content_hint mt-3'>Download RoRo Wallet</p>
                </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/Hand_of_user.png')}/>
                <p className='explore_content_hint text-center mt-3'>Create a new Wallet</p>
                </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/buy_some_crypto.png')}/>
                <p className='explore_content_hint text-center mt-3'>Get some Crypto</p>
                </div>
                
            </Col>
            <div className='w-100 mt-5 d-flex justify-content-center'>
        <button className='explore_content_download_btn'>Let's Go</button>
        </div>
        </Row>
    </Container>
    <Container fluid className='features_opt_dtls mt-5'>
        <div className='w-100 pt-4 pb-4'>
    <h3 className='text-center doodle_title'>Get RoRo Wallet now</h3>
        <p className='text-center explore_content_hint'>The most Trusted & Secure wallet</p>
        <div className='container '>
            <div className='row'>
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_firstdowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align' src={require('../assets/images/apple_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>APP STORE</h6>
                </div>
            </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_seconddowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/playstore_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>Google Play</h6>
                </div>
            </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_thirddowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/android_doll.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>Android</h6>
                </div>
            </div>
                    
                </div>


            </div>
        </div>
        </div>
        

    </Container>
    <Footer/>
    </>
  )
}

export default Staking