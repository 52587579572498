import React,{useEffect} from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { Container,Row,Col } from 'react-bootstrap'

function NftHub() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
    <div className='header_bar_sticky_align'>
    <Header/>
    </div>
    <Container fluid className='banner_bg'>
        <Container>

        <Row className='banner_top_align'>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className='banner_align_dtls d-flex justify-content-center h-100 align-items-center'>
                <div className='wrap_dtls'>
            <h1 className='banner_title'>NFT Hub for All Your <br/> Treasured Collectibles</h1>
            <p className='stacking_clearance_txt mt-3'>RoRo Wallet lets you easily store all of your NFTs and gaming assets in one location. NFT-Crazy? So Are We! Show Your Crypto Love with RoRo Wallet.
</p>
            <div className='banner_btn_align mt-5 pb-5'>
             
            <button className='download_now_btn_align'>Coming soon</button>
            </div>
            </div>
            </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <img className='img-fluid' src={require('../assets/images/RORO Website inner pages images/NFT Page/bannerblackphone.png')}></img>
            </Col>
        </Row>

        </Container>
    </Container>

    <Container>
    <Row className='row_reverse_align mt-5'>
        <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
            <img className='img-fluid' src={require('../assets/images/RORO Website inner pages images/NFT Page/vrcoin.png')}></img>
        </Col>
        <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className='wrap_dtls_align h-100 d-flex  align-items-center'>
            <div className='wrap_dtls'>
            <h2 className='doodle_title'>What are NFTs?</h2>
            <p className='stacking_clearance_txt mt-4'>NFTs are digital collectibles that are unique and cannot be replicated or replaced. Think of them like rare, one-of-a-kind trading cards, but instead of being physical, they exist only in the digital world.
</p>

            <p className='stacking_clearance_txt mt-3'>You can own NFTs, trade them, or use them as a form of digital currency. They're often used to represent things like art, music, videos, or virtual real estate. Because they're unique and stored on a blockchain network, NFTs allow for a new way to own and trade digital assets in a secure and transparent way.
</p>
            </div>
            </div>
        </Col>
        </Row>

        <Row className='mt-5'>
        <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className='wrap_dtls_align h-100 d-flex  align-items-center'>
            <div className='wrap_dtls'>
            <h2 className='doodle_title'>So how do I store them?</h2>
            <p className='stacking_clearance_txt mt-4'>RoRo Wallet  gives you a secure, convenient way to store and access all of your digital collectibles and have a public address which is protected on the blockchain.</p>

            <p className='stacking_clearance_txt mt-3'>The popularity of numerous cryptocurrency collectibles such as CryptoPunks, CryptoKitties, Decentraland, Axies, and others has grown over the past several years.</p>
            </div>
            </div>
        </Col>
        <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
            <img className='img-fluid' src={require('../assets/images/RORO Website inner pages images/NFT Page/manmonkey.png')}></img>
        </Col>
        </Row>
    </Container>
    

    <Container className='mt-5'>
    <h2 className='doodle_title'>RoRo supports NFTs from</h2>
    <Row>
      <Col className='px-0 ' xxl={3} xl={3} lg={3} md={6} sm={6} xs={12}>
      <div className='world_asset_div_alignOne'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/xdc_icon.png')}/>
                    <p className='m-0 p-0'><b>Xinfin Network </b> (XDC)</p>
                </div>
      </Col>
      <Col className='px-0' xxl={3} xl={3} lg={3} md={6} sm={6} xs={12}>
      <div className='world_asset_div_alignOne'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/ethereum.png')}/>
                    <p className='m-0 p-0'><b>Ethereum </b> (ETH)</p>
                </div>
      </Col>
      <Col className='px-0' xxl={3} xl={3} lg={3} md={6} sm={6} xs={12}>
      <div className='world_asset_div_alignOne'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/polygon.png')}/>
                    <p className='m-0 p-0'><b>Polygon </b> (matic)</p>
                </div>
      </Col>
      <Col className='px-0' xxl={3} xl={3} lg={3} md={6} sm={6} xs={12}>
      <div className='world_asset_div_alignOne'>
                    <img className='tokens_img_align' src={require('../assets/images/asset page/wanchain.png')}/>
                    <p className='m-0 p-0'><b>Wanchain </b> (WAN)</p>
                </div>
      </Col>
    </Row>

    </Container>

    <Container className='mt-5'>
        <h3 className='text-center doodle_title'>Dive into crypto with 3 easy steps</h3>
        <p className='text-center doodle_title'>It only takes few minutes</p>
    <Row className='mt-5 justify-content-around' >
            <Col  xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/download_roro.png')}/>
                <p className='explore_content_hint mt-3'>Download RoRo Wallet</p>
                </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/Hand_of_user.png')}/>
                <p className='explore_content_hint text-center mt-3'>Create a new Wallet</p>
                </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/buy_some_crypto.png')}/>
                <p className='explore_content_hint text-center mt-3'>Get some Crypto</p>
                </div>
                
            </Col>
            <div className='w-100 mt-5 d-flex justify-content-center'>
        <button className='explore_content_download_btn'>Let's Go</button>
        </div>
        </Row>
    </Container>
    <Container fluid className='features_opt_dtls mt-5'>
        <div className='w-100 pt-4 pb-4'>
    <h3 className='text-center doodle_title'>Get RoRo Wallet now</h3>
        <p className='text-center explore_content_hint'>The most Trusted & Secure wallet</p>
        <div className='container '>
            <div className='row'>
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_firstdowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align' src={require('../assets/images/apple_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>APP STORE</h6>
                </div>
            </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_seconddowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/playstore_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>Google Play</h6>
                </div>
            </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_thirddowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/android_doll.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>Android</h6>
                </div>
            </div>
                    
                </div>


            </div>
        </div>
        </div>
        

    </Container>
    <Footer/>
    </>
  )
}

export default NftHub