import React,{useState,useEffect} from 'react'
import { Col,Row, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { toast } from "react-toastify";

function Footer() {
    var backurl = "https://api.rorowallet.com";
    var EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    var [email,setEmail] = useState('');
    var [Error,SetError] = useState({});

    const facebook = () =>{
        window.open( 'https://www.facebook.com/profile.php?id=100088318758031&mibextid=LQQJ4d','_blank');  
    }
    const instagram = () =>{
        window.open( 'https://instagram.com/rorowallet?igshid=YmMyMTA2M2Y=','_blank');
    }
    const twitter = () =>{
        window.open( 'https://twitter.com/RoRowallet?t=m3EVeWBg9MNtB_fDgrzQeQ&s=09','_blank');
    }
    const isEmpty = value =>
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (typeof value === 'string' && value === '0')||
    (typeof value === 'number' && value === 0);
    const NewsLetter = async() =>{
        const id = toast.loading("Subscribing...");
        
        let err = {};
        if(email=='') err.email="Email Id Required";
        if(email && !(EMAIL).test(email)) err.email = 'Invalid Email ID Format';
        SetError(err);
        // console.log('fhbdfhbdf',email)
        if(isEmpty(err)){
            let resp = await Newsletter({email : email});
            if(resp.success === 'success'){
                toast.update(id ,{render:"succeffully Subcribed for NewsLetter", type: "success", isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
                setEmail('');
            }
            else{
                toast.update(id ,{ render : "Already Subcribed", type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
                SetError({email:resp.msg});
            }
        }
        else{
            toast.update(id ,{ render : err.email, type :'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true})
        }
    }
    const Newsletter = async (data) =>{
        var senddata ={
            method  :   'POST',
            url     :   `${backurl}/newsletter`,
            data    :    data
        }
        console.log('hdghgh',senddata)
        let Resp = await axiosFunc(senddata);
        
        return Resp.data;
    }
    const axiosFunc    =   async   (data)  =>  {
        try{
            let Resp    =   await axios(data)
            // console.log('resppppp',Resp,data)
            return Resp
        }
        catch(e){
            return {success:'error',msg:null}
        }
      }
  return (
    <>
    <Container fluid className='footer'>
        <Container>
        <Row className='justify-content-center '>
            <Col className='pt-5 footer_listing' xxl={3} xl={3} lg={3} md={4} sm={4} xs={12}>
            <div className='social_icons_dtls'>
                <Link to='/'>
            <img className='img-fluid footer_logo_img' src={require('../assets/images/logo.png')}/>
            </Link>
            </div>
            <div className='social_icons_dtls  mt-3'>
            <div className='twitter_align me-2'>
                {/* <Link to='  https://www.facebook.com/profile.php?id=100088318758031&mibextid=LQQJ4d '> */}
            <img onClick={facebook} className='img-fluid  ' src={require('../assets/images/FACEBOOK.png')}/>
            {/* </Link> */}
            </div>
            <div className='twitter_align me-2'>
                {/* <Link target='_blank' to=' https://instagram.com/rorowallet?igshid=YmMyMTA2M2Y= '> */}
            <img className='img-fluid' onClick={instagram} src={require('../assets/images/insta.png')}/>
            {/* </Link> */}
           
            </div>
            <div className='twitter_align me-2'>
                {/* <Link to=' https://twitter.com/RoRowallet?t=m3EVeWBg9MNtB_fDgrzQeQ&s=09 '> */}
            <img className='img-fluid ' onClick={twitter} src={require('../assets/images/twitter1.png')}/>
            {/* </Link> */}
            </div>
            {/* <div className='twitter_align me-2'>
            <img className='img-fluid ' src={require('../assets/images/telegram.png')}/>
            
            </div> */}
            </div>

            </Col>
            
            <Col className='footer_listing pt-5' xxl={2} xl={2} lg={2} md={4} sm={4} xs={12}>
                <p>RoRo Wallet</p>
                <ul>
                    <li><Link className='link_tag_align' to="/secureStore">Assets</Link></li>
                    <li><Link className='link_tag_align' to="/staking">Staking</Link></li>
                    <li><Link className='link_tag_align' to="/nfthub">NFTs</Link></li>
                    <li><Link className='link_tag_align' to="/dappbrowser">DApp Browser</Link></li>
                    
                   
                </ul>
                 </Col>
                 <Col className='footer_listing pt-5' xxl={3} xl={3} lg={3} md={4} sm={4} xs={12}>
                    <div>
                <p>Information</p>
                <ul>
                <li>Get your project listed on RoRo</li>
                    <li><Link className='link_tag_align' to='/privacyPolicy'> Privacy Policy </Link></li>
                    
                   
                    
                </ul>
                </div>
                 </Col>
                
                 <Col className='footer_listing pt-5' xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
                    <div>
                <p>Subscribe to our Newsletter</p>
                <div className='email_input_dtls mb-2'>
                    <input className='footer_mail_input_align' type='email' placeholder='Email Address' value={''||email} onChange={(event)=>{setEmail(event.target.value)}} required="" id="subscribe-email"  autoComplete="off" />
                    <button className='download_now_btn_align' id="subscribe-button" onClick={NewsLetter}>Subscribe</button>
                </div>
                
                </div>
                 </Col>
                 </Row>

        </Container>
    </Container></>
  )
}

export default Footer