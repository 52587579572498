import React from 'react'
import {Button,Container,Form,Nav,Navbar,NavDropdown,Offcanvas} from 'react-bootstrap';
import { Link } from 'react-router-dom';


function Header() {
  return (
    <>
    {['lg'].map((expand) => (
      <Navbar key={expand} bg="light" expand={expand} className="main_navbar">
        <Container >
          <Navbar.Brand ><Link to='/'><img className='header_main_logo' src={require('../assets/images/logo.png')}/></Link></Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end" 
            className='header_offcanva_align'
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link className='header_links_align'><Link to='/secureStore' className='link_tag_alignhead'>Assets</Link></Nav.Link>
                <Nav.Link className='header_links_align'><Link to='/staking' className='link_tag_alignhead'>Staking</Link></Nav.Link>
                <Nav.Link className='header_links_align'><Link to='/nfthub' className='link_tag_alignhead'>NFTs</Link></Nav.Link>
                <Nav.Link className='header_links_align'><Link to='/dappBrowser' className='link_tag_alignhead'>Dapp Browser</Link></Nav.Link>
                
              </Nav>
              <Form className="d-flex">
                {/* <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                /> */}
                
              </Form>
              <Button className='header_download_btn'>Coming soon</Button>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    ))}
  </>
  )
}

export default Header