import React,{useEffect} from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import { Link } from 'react-router-dom'

function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
    <div className='header_bar_sticky_align'>
    <Header/>
    </div>
    <Container>
    <h3 className='text-center doodle_title mt-5'>Privacy Policy</h3>
    <p className='stacking_clearance_txt text-center mt-3'>At RoRo Wallet, we take the privacy of our users very seriously. This privacy policy outlines the information we collect, how we use it, and the measures we take to protect it.
Information Collection
</p>

    <h6 className='mt-5'><b>Information Collection</b></h6>
    <p className='stacking_clearance_txt'>We collect the following information from users:</p>

    <div className='collection_dtls_align'>
        <div className='yellow_round_align'></div>
        <p className=' m-0 stacking_clearance_txt'>Email address (newsletters)</p>
    </div>

    <div className='collection_dtls_align'>
        <div className='yellow_round_align'></div>
        <p className=' m-0 stacking_clearance_txt'>Wallet address (blockchain)</p>
    </div>

    <div className='collection_dtls_align'>
        <div className='yellow_round_align'></div>
        <p className=' m-0 stacking_clearance_txt'>Transaction data (blockchain)</p>
    </div>

    <h6 className='mt-5'><b>Data Storage</b></h6>
    <p className='stacking_clearance_txt'>User information is stored securely on encrypted servers, and all sensitive data is encrypted. We take every precaution to ensure the security of our users' information and assets.The user data can only be accessed by the user, not even roro will have access to this.
</p>

    <h6 className='mt-5'><b>Sharing of Information</b></h6>
    <p className='stacking_clearance_txt'>We do not sell or share user information with third parties, except as required by law.
</p>

    <h6 className='mt-5'><b>Access of Information</b></h6>
    <p className='stacking_clearance_txt'>Users can access and update their personal information at any time by logging into their RoRo Wallet account.
</p>

    <h6 className='mt-5'><b>Security Measures</b></h6>
    <p className='stacking_clearance_txt'>We use state-of-the-art security measures, such as encryption and multi-signature technology, to protect user information and assets.
</p>

    <h6 className='mt-5'><b>Third-Party Services</b></h6>
    <p className='stacking_clearance_txt'>We may use third-party services to provide certain services, such as payment processing. These third parties may have access to user information, but they are contractually obligated to protect it and use it only for the purpose of providing their services.</p>

    

    <h6 className='mt-5'><b>Changes to the Policy</b></h6>
    <p className='stacking_clearance_txt'>We reserve the right to modify this privacy policy at any time. If we make any material changes, we will notify users via email or by posting a notice on our website.</p>

    <h6 className='mt-5'><b>Contact Information</b></h6>
    <p className='stacking_clearance_txt'>If you have any questions or concerns about our privacy policy, please contact us at  <Link to='#' className='support_email_txt_align'>support@rorowallet.com
</Link></p>


    </Container>

    <Container className='mt-5'>
        <h3 className='text-center doodle_title'>Dive into crypto with 3 easy steps</h3>
        <p className='text-center doodle_title'>It only takes few minutes</p>
    <Row className='mt-5 justify-content-around' >
            <Col  xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/download_roro.png')}/>
                <p className='explore_content_hint mt-3'>Download RoRo Wallet</p>
                </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/Hand_of_user.png')}/>
                <p className='explore_content_hint text-center mt-3'>Create a new Wallet</p>
                </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/buy_some_crypto.png')}/>
                <p className='explore_content_hint text-center mt-3'>Get some Crypto</p>
                </div>
                
            </Col>
            <div className='w-100 mt-5 d-flex justify-content-center'>
        <button className='explore_content_download_btn'>Let's Go</button>
        </div>
        </Row>
    </Container>
    <Container fluid className='features_opt_dtls mt-5'>
        <div className='w-100 pt-4 pb-4'>
    <h3 className='text-center doodle_title'>Get RoRo Wallet now</h3>
        <p className='text-center explore_content_hint'>The most Trusted & Secure wallet</p>
        <div className='container '>
            <div className='row'>
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_firstdowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align' src={require('../assets/images/apple_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>APP STORE</h6>
                </div>
            </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_seconddowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/playstore_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>Google Play</h6>
                </div>
            </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_thirddowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/android_doll.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>Android</h6>
                </div>
            </div>
                    
                </div>


            </div>
        </div>
        </div>
        

    </Container>
    <Footer/>
    </>
  )
}

export default PrivacyPolicy