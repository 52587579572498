
import React from "react"
import './App.css';
import RoutesDtls from './routesAll/RoutesDtls';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
    <RoutesDtls/>

    <ToastContainer
				position="top-right"
				autoClose={1000}
				hideProgressBar={false}
				rtl={true}
				closeOnClick={true}
        closeButton={true}
				style={
					{zIndex:"999999",
					"width":"400px",
					"wordBreak":"break-word",
					"text-align":"center",
					"fontWeight":"500",
					marginTop:"70px"
				}} 
			/>  
      
    </>
    
  );
}

export default App;
