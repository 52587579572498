import React,{useEffect} from 'react'
import { Col,Row, Container } from 'react-bootstrap'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function HomePage() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
    <div className='header_bar_sticky_align'>
    <Header/>
    </div>
<Container fluid className='banner_bg'>
    <Container>
        <Row className='banner_top_align'>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className='banner_align_dtls d-flex justify-content-center h-100 align-items-center'>
                <div className='wrap_dtls'>
            <h1 className='banner_title'>Web3 just got <br/> a lot easier with RoRo</h1>
            <p className='banner_hint mt-3'> Securely chat,buy,send, receive cryptocurrencies, and store NFTs all in a decentralised way</p>
            <div className='banner_btn_align mt-5 pb-5'>
             
            <div className='android_icon_dtls me-4 mb-3 download_icon_dtls'>
                <img className='download_icons_align' src={require('../assets/images/apple_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'> Coming soon</p>
                    <h6 className='download_hint_align'>APP STORE</h6>
                </div>
            </div>
            <div className='android_icon_dtls mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/playstore_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>Google Play</h6>
                </div>
            </div>
            
            </div>
            </div>
            </div>
            </Col>
            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <img className='img-fluid' src={require('../assets/images/banner_header.png')}></img>
            </Col>
        </Row>
    </Container>
    </Container>

    <Container fluid className='features_opt_dtls pt-1'>
        <Container>
            <Row className='justify-content-center align-items-center'>
                <Col className='mt-2' xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                    <div className='text-center w-100 pt-1'>
                        <img className='yellow_header_img_align' src={require('../assets/images/chat.png')}/>
                {/* <i class="bi bi-chat-left-text features_icons"></i> */}
                <p className='features_hint_txt'>In App decentralised  messaging</p>
                </div>
                </Col>
                <Col className='mt-2' xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                <div className='text-center w-100 '>
                <img className='yellow_header_img_align' src={require('../assets/images/icons_wallet.png')}/>
                {/* <i class="bi bi-credit-card features_icons"></i> */}
                <p className='features_hint_txt pt-1'>Buy Crypto with a Card</p>
                </div>
                </Col>
                <Col className='mt-2' xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                <div className='text-center w-100 pt-1'>
                <img className='yellow_header_img_align' src={require('../assets/images/exchange.png')}/>
                {/* <i class="bi bi-currency-exchange features_icons"></i> */}
                <p className='features_hint_txt'>Instantly Exchange</p>
                </div>
                </Col>
                <Col className='mt-2' xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                <div className='text-center w-100 pt-1'>
                <img className='shield_img_align' src={require('../assets/images/shield.png')}/>
                {/* <i class="bi bi-shield-check features_icons"></i> */}
                <p className='features_hint_txt'>Private and Secure</p>
                </div>
                </Col>
            </Row>
        </Container>
    </Container>

    <Container className='mt-5'>
        <h3 className='text-center doodle_title'>Let's Explore WEB3 together</h3>
        <p className='text-center explore_content_hint'>RoRo is for everyone who wants to </p>
        <Row className='justify-content-center'>
            <Col className='mb-3' xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
            <div className='text-center w-100 py-2'>
                <img className='web3_images_align' src={require('../assets/images/binance_round.png')}/>
            {/* <i class="bi bi-currency-bitcoin explore_content_icons"></i> */}
            <p className='explore_content_hintone'>Buy Cryptocurrency with no hassle</p>
            </div>

            </Col>
            <Col className='mb-3' xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
            <div className='text-center w-100 py-2'>
            {/* <i class="bi bi-currency-dollar explore_content_icons"></i> */}
            <img className='web3_images_align' src={require('../assets/images/grid_dollar.png')}/>
            <p className='explore_content_hintone'>Earn interest on crypto</p>
            </div>

            </Col>
            <Col className='mb-3' xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
            <div className='text-center w-100 py-2'>
            {/* <i class="bi bi-lock explore_content_icons"></i> */}
            <img className='web3_images_align' src={require('../assets/images/pootu.png')}/>
            <p className='explore_content_hintone'>Securely store and manage NFT's</p>
            </div>

            </Col>
            <Col className='mb-3' xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
            <div className='text-center w-100 py-2'>
            {/* <i class="bi bi-send explore_content_icons"></i> */}
            <img className='web3_images_align' src={require('../assets/images/rocket.png')}/>
            <p className='explore_content_hintone'>Exchange crypto instantly</p>
            </div>

            </Col>
            <Col className='mb-3' xxl={4} xl={4} lg={4} md={6} sm={12} xs={12}>
            <div className='text-center w-100 py-2'>
            <img className='web3_images_align' src={require('../assets/images/chat_double.png')}/>
            {/* <i class="bi bi-chat-left-text explore_content_icons"></i> */}
            <p className='explore_content_hintone'>Interact with peers on chat, request and send payments all in a decentralised way</p>
            </div>

            </Col>
        </Row>
        <div className='w-100 d-flex justify-content-center'>
        <button className='explore_content_download_btn'>Coming soon</button>
        </div>

    </Container>
    <Container className='mt-5'>
        <Row>
        <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className='wrap_dtls_align h-100 d-flex align-items-center'>
            <div className='wrap_dtls'>
            <h2 className='doodle_title'>Buy Crypto with a card</h2>
            <p className='explore_content_hint'>Get your first $20 of Bitcoin, Ethereum, Binance Coin, XDC Network, Wanchain, Ripple and many more!</p>
            </div>
            </div>
        </Col>
        <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
            <img className='img-fluid' src={require('../assets/images/crypto_card.png')}></img>
        </Col>
        </Row>
        <Row className='row_reverse_align'>
        <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
            <img className='img-fluid' src={require('../assets/images/exchange_instant.png')}></img>
        </Col>
        <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className='wrap_dtls_align h-100 d-flex  align-items-center'>
            <div className='wrap_dtls'>
            <h2 className='doodle_title'>Exchange Instantly</h2>
            <p className='explore_content_hint'>Trade and Exchange crypto with ease, no selfies, no forms, no hassle!</p>
            </div>
            </div>
        </Col>
        </Row>
        <Row>
        <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className='wrap_dtls_align h-100 d-flex  align-items-center'>
            <div className='wrap_dtls'>
            <h2 className='doodle_title'>Private & Secure</h2>
            <p className='explore_content_hint'>We don't collect any perosnal data from you! We want all the control to be in your hands</p>
            </div>
            </div>
        </Col>
        <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
            <img className='img-fluid' src={require('../assets/images/private_secure.png')}></img>
        </Col>
        </Row>
        <Row className='row_reverse_align'>
        <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
            <img className='img-fluid' src={require('../assets/images/browse_dapps.png')}></img>
        </Col>
        <Col className='mb-4' xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className='wrap_dtls_align h-100 d-flex  align-items-center'>
            <div className='wrap_dtls'>
            <h2 className='doodle_title'>Browse for Dapps</h2>
            <p className='explore_content_hint'>Browse a never-ending collection of Dapps(decentralised application),interact with them, explore new Dapps all without leaving your wallet.</p>
            </div>
            </div>
        </Col>
        </Row>


        <h3 className='text-center mt-5 doodle_title'>Dive into crypto with 3 easy steps</h3>
        <p className='text-center explore_content_hint'>It only takes few minutes</p>

        <Row className='mt-5 justify-content-around'>
            <Col  xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/download_roro.png')}/>
                <p className='explore_content_hintone mt-3'>Download RoRo Wallet</p>
                </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/Hand_of_user.png')}/>
                <p className='explore_content_hintone text-center mt-3'>Create a new Wallet</p>
                </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/buy_some_crypto.png')}/>
                <p className='explore_content_hintone text-center mt-3'>Get some Crypto</p>
                </div>
            </Col>
        </Row>
        <div className='w-100 mt-5 d-flex justify-content-center'>
        <button className='explore_content_download_btn'>Let's Go</button>
        </div>
    </Container>

    <Container fluid className='features_opt_dtls mt-5'>
        <div className='w-100 pt-4 pb-4'>
    <h3 className='text-center doodle_title'>Get RoRo Wallet now</h3>
        <p className='text-center explore_content_hint'>The most Trusted & Secure wallet</p>
        {/* <div className='w-100 mt-4 explore_content_download_align'>
        
<div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align' src={require('../assets/images/apple_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Download on the</p>
                    <h6 className='download_hint_align'>APP STORE</h6>
                </div>
            </div>
            <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/playstore_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Download on the</p>
                    <h6 className='download_hint_align'>Google Play</h6>
                </div>
            </div>
            <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/android_doll.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Download on the</p>
                    <h6 className='download_hint_align'>Google Play</h6>
                </div>
            </div>
        </div> */}

        <div className='container '>
            <div className='row'>
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_firstdowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align' src={require('../assets/images/apple_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>APP STORE</h6>
                </div>
            </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_seconddowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/playstore_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>Google Play</h6>
                </div>
            </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_thirddowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/android_doll.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>Android</h6>
                </div>
            </div>
                    
                </div>


            </div>
        </div>
        </div>
        

    </Container>

    <Footer/>

    
    </>
  )
}

export default HomePage