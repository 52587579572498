import React,{useEffect} from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function Application() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <div className='header_bar_sticky_align'>
    <Header/>
    </div>
    <Container className='mt-5'>
    <h3 className='text-center doodle_title'>RoRo Wallet Project Application</h3>
    <p className='stacking_clearance_txt text-center'>RoRo wallet is great place to showcase and add more uses to your project! If you would like to get your application listed with RoRo please follow the below steps:
</p>
    <div className='whole_steps_align'>
        <div>
            <p><b>Step:1 </b> Fill out the form below for your listing request
</p>
            <p><b>Step:2 </b>  A member from our team will reach out to you </p>
            <p><b>Note: </b> Using the from does not guarantee that your application will be listed</p>
        </div>
    </div>

    <div className='box_text_align'>
    <h2 className='doodle_title text-center'>DApp Marketplace Application</h2>
    <p className='text-center'>After you fill out this form, we will review it and contact you in case we decided to list your app.</p>
    </div>
    <div>
        <div className='switch_acnt_align'>
            <div>
                <div className='switch_acnt_txt_align'>
            <i class="bi bi-eye-slash-fill switch_acnt_icon_align"/> &nbsp; <b> kjgdsgj@gmail.com</b> &nbsp;<small>(not Shared)</small> &nbsp;<p className='blue_text'> Switch Account</p>
            </div>
            <p className='danger_text p-0 m-0'>* Required</p>
            </div>
            <i class="bi bi-clouds-fill switch_acnt_icon_align"></i>
        </div>
    </div>

    <div className='box_text_align'>
        <p>Dapp Name of Listing <span className='danger_text'>*</span></p>
        <input className='name_input_align' type='text' placeholder='Your answer'/>
    </div>

    <div className='box_text_align'>
        <p className='p-0 m-0'>Dapp Name of Listing <span className='danger_text'>*</span></p>
        <small>Give a short and clear explanation about Dapp functionality</small><br/>
        <input className='name_input_align mt-3'  type='text' placeholder='Your answer'/>
    </div>

    <div className='box_text_align'>
        <p>Dapp description for listing<span className='danger_text'>*</span></p>
        <input className='name_input_align' type='text' placeholder='Your answer'/>
    </div>

    <div className='box_text_align'>
        <p>Dapp URL for listing<span className='danger_text'>*</span></p>
        <input className='name_input_align' type='text' placeholder='Your answer'/>
    </div>

    <div className='box_text_align'>
        <p>Dapp Category?(can be multiple categories)<span className='danger_text'>*</span></p>
        <div class="input-group mb-3">
  <div class="input-group-text input_group_text_align">
    <input class="form-check-input form_check_input_align mt-0" type="checkbox" value="" aria-label="Checkbox for following text input"/>
  </div>
  <label  class="form-control input_group_label_align " aria-label="Text input with checkbox">Exchange</label>
</div>

<div class="input-group mb-3">
  <div class="input-group-text input_group_text_align">
    <input class="form-check-input form_check_input_align mt-0" type="checkbox" value="" aria-label="Checkbox for following text input"/>
  </div>
  <label  class="form-control input_group_label_align " aria-label="Text input with checkbox">Social</label>
</div>

<div class="input-group mb-3">
  <div class="input-group-text input_group_text_align">
    <input class="form-check-input form_check_input_align mt-0" type="checkbox" value="" aria-label="Checkbox for following text input"/>
  </div>
  <label  class="form-control input_group_label_align " aria-label="Text input with checkbox">Utility</label>
</div>

<div class="input-group mb-3">
  <div class="input-group-text input_group_text_align">
    <input class="form-check-input form_check_input_align mt-0" type="checkbox" value="" aria-label="Checkbox for following text input"/>
  </div>
  <label  class="form-control input_group_label_align " aria-label="Text input with checkbox">Marketplace</label>
</div>

<div class="input-group mb-3">
  <div class="input-group-text input_group_text_align">
    <input class="form-check-input form_check_input_align mt-0" type="checkbox" value="" aria-label="Checkbox for following text input"/>
  </div>
  <label  class="form-control input_group_label_align " aria-label="Text input with checkbox">Game</label>
</div>

<div class="input-group mb-3">
  <div class="input-group-text input_group_text_align">
    <input class="form-check-input form_check_input_align mt-0" type="checkbox" value="" aria-label="Checkbox for following text input"/>
  </div>
  <label  class="form-control input_group_label_align " aria-label="Text input with checkbox">Staking functionality availble</label>
</div>






    </div>


    <div className='box_text_align'>
        <p>I certify that this Dapp has been tested in Trust Wallet's browser for functional and visual defects<span className='danger_text'>*</span></p>
        <div class="input-group">
  <div class="input-group-text input_group_text_align">
    <input class="form-check-input form_check_input_align    mt-0" type="radio" id="excellent" name="position" value="excellent" aria-label="Radio button for following text input"/>
  </div>
  <label  for="position" class="form-control input_group_label_align " aria-label="Text input with checkbox">Yes, the dapp has been tested; no functional or visual issues found</label>
</div>

<div class="input-group">
  <div class="input-group-text input_group_text_align">
    <input class="form-check-input form_check_input_align    mt-0" type="radio" id="middle" name="position" value="middle" aria-label="Radio button for following text input"/>
  </div>
  <label for='position'  class="form-control input_group_label_align " aria-label="Text input with checkbox">Yes, bug fixes are pending</label>
</div>

<div class="input-group">
  <div class="input-group-text input_group_text_align">
    <input class="form-check-input form_check_input_align    mt-0" type="radio" id="poor" name="position" value="poor" aria-label="Radio button for following text input"/>
  </div>
  <label for='position' class="form-control input_group_label_align " aria-label="Text input with checkbox">No, the dapp as not yet been tested with Trust Wallet</label>
</div>
    </div>

    <div className='box_text_align'>
        <p>Telegram username<span className='danger_text'>*</span></p>
        <input className='name_input_align' type='text' placeholder='Your answer'/>
    </div>

    <div className='box_text_align'>
        <p>Email Address<span className='danger_text'>*</span></p>
        <input className='name_input_align' type='text' placeholder='Your answer'/>
    </div>

    <div className='clear_form_div_align'>
    <button className='download_now_btn_align'>Coming soon</button>
    <p className='small_skyblue_text p-0 m-0'>clear form</p>
    </div>
    <p className='mt-3 never_submit_text'>Never submit passwords through Google Forms.</p>


    </Container>


    <Container className='mt-5'>
        <h3 className='text-center doodle_title'> Dive into crypto with 3 easy steps</h3>
        <p className='text-center doodle_title'>It only takes few minutes</p>
    <Row className='mt-5 justify-content-around' >
            <Col  xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
                <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/download_roro.png')}/>
                <p className='explore_content_hint mt-3'>Download RoRo Wallet</p>
                </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/Hand_of_user.png')}/>
                <p className='explore_content_hint text-center mt-3'>Create a new Wallet</p>
                </div>
            </Col>
            <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12}>
            <div className='simple_steps_dtls w-100'>
                <img className='img-fluid simple_steps_img' src={require('../assets/images/buy_some_crypto.png')}/>
                <p className='explore_content_hint text-center mt-3'>Get some Crypto</p>
                </div>
                
            </Col>
            <div className='w-100 mt-5 d-flex justify-content-center'>
        <button className='explore_content_download_btn'>Let's Go</button>
        </div>
        </Row>
    </Container>
    <Container fluid className='features_opt_dtls mt-5'>
        <div className='w-100 pt-4 pb-4'>
    <h3 className='text-center doodle_title'>Get RoRo Wallet now</h3>
        <p className='text-center explore_content_hint'>The most Trusted & Secure wallet</p>
        <div className='container '>
            <div className='row'>
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_firstdowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align' src={require('../assets/images/apple_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'> Coming soon</p>
                    <h6 className='download_hint_align'>APP STORE</h6>
                </div>
            </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_seconddowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/playstore_icon.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>Google Play</h6>
                </div>
            </div>
                </div>

                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 whole_thirddowload_icon_dtls_align'>
                <div className='android_icon_dtls  mb-3 download_icon_dtls'>
                <img className='download_icons_align'  src={require('../assets/images/android_doll.png')}/>
                <div className='text_dtls_align'>
                    <p className='download_txt_align'>Coming soon</p>
                    <h6 className='download_hint_align'>Android</h6>
                </div>
            </div>
                    
                </div>


            </div>
        </div>
        </div>
        

    </Container>

    <Footer/>
    </>
  )
}

export default Application